import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home1 from "./Home1";
import Home2 from "./Home2";
import Portfolio1 from "./Portfolio1";
import Portfolio2 from "./Portfolio2";
import Project1 from "./Project1";
import Project2 from "./Project2";
import Project3 from "./Project3";
import Project4 from "./Project4";
import Project5 from "./Project5";
import Project6 from "./Project6";
import Publication from "./Publication";
import Service from "./Service";
import Services from "./Services";
import Team from "./Team";
import Page404 from "./404";
import BlogInner from "./BlogInner";
import Blog from "./Blog";
import Contact from "./Contact";
import Portfolio3 from "./Portfolio3";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home1 />} />
        
        <Route path="/home2" element={<Home2 />} />
        <Route path="/portfolio1" element={<Portfolio1 />} />
        <Route path="/portfolio2" element={<Portfolio2 />} />
        <Route path="/portfolio3" element={<Portfolio3 />} />
        <Route path="/project1" element={<Project1 />} />
        <Route path="/project2" element={<Project2 />} />
        <Route path="/project3" element={<Project3 />} />
        <Route path="/project4/:id" element={<Project4 />} />
        <Route path="/project5" element={<Project5 />} />

        <Route path="/project6" element={<Project6 />} />
        <Route path="/publication/:id" element={<Publication />} />
        <Route path="/service/:id" element={<Service />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog-inner" element={<BlogInner />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/team" element={<Team />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
