"use client";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import ReactPlayer from "react-player";


const VideoSection = ({ videoUrl, image }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  return (
    <div className="video-section-container">
      <div className="video-section-wrapper">
        {/* Background Section */}
        <div
          className="video-background"
          style={{ backgroundImage: `url(${image})` }}
        >
          {/* Overlay */}
          <div className="video-overlay" />

          {/* Play Button */}
          <button
            onClick={() => setShowVideoModal(true)}
            className="video-play-button"
          >
            <i className="fa fa-play video-play-icon" />
            <span className="video-ping" />
            {/* Inverted Triangle */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="video-play-svg"
            >
              <path
                fillRule="evenodd"
                d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Video Modal */}
      {showVideoModal && (
        <div className="video-modal-container">
          <div className="video-modal-inner">
            <button
              onClick={() => setShowVideoModal(false)}
              className="video-close-button"
            >
              <XMarkIcon className="video-close-icon" />
            </button>
            <ReactPlayer url={videoUrl} width="100%" height="500px" />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoSection;
